<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>yClients</h2>
                <HintIcon hintId="ycAuthKey" class="ml-2"></HintIcon>
            </v-row>

            <v-form v-model="valid">
                <TextField
                    color="main"
                    prependInnerIcon="mdi-email"
                    filled
                    v-model="login"
                    label="Логин"
                    required
                    persistent-hint
                    hint="Обязательно"
                    tooltipText="Используйте ваш логин yClients"
                ></TextField>

                <TextField
                    type="password"
                    prependInnerIcon="mdi-earth"
                    color="main"
                    filled
                    v-model="password"
                    label="Пароль"
                    required
                    persistent-hint
                    hint="Обязательно"
                    tooltipText="Используйте ваш пароль yClients"
                ></TextField>
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        prependInnerIcon="mdi-key"
                        color="main"
                        filled
                        v-model="apiKey"
                        label="Ключ разработчика"
                        required
                        persistent-hint
                        hint="Обязательно"
                    ></TextField>
                </v-row>

                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn color="main" class="white--text" @click="handleLogin" :disabled="btnDisabled">Войти</v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'

import cfg from '../../../../config'
export default {
    name: 'YcAuth',
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        login: '',
        password: '',
        apiKey: '',
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (!this.apiKey || !this.login || !this.password || !this.valid) {
                return true
            }
            return false
        },
    },
    methods: {
        async handleLogin() {
            console.log('ok')
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: 'yClients',
            })
            console.log(this.$store)
            const isLogin = await this.$store.dispatch('yc/login', {
                creds: {
                    login: this.login,
                    password: this.password,
                    apiKey: this.apiKey,
                },

                connector: connector,
            })

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>
