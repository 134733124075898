import cfg from '../../../config'
import { getInstance } from '../../auth'
import { Connector } from '../../types/main'
import { getError } from '../../utils'

export async function saveCredentials(
    connector: Connector,
    credentials: { pfLogin: string; pfPassword: string }
) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/profSalon/bat/saveCredentials`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            ...connector,
            ...credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status > 400) {
        return { data: null, error: getError(res) }
    } else {
        return { data: await res.json(), error: null }
    }
}
