import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { Connector } from '../../types/main'
interface KlientixCredentials {
    access_token: string
    user_id: string
    account_id: string
}

export async function login(connector: Connector, credentials: KlientixCredentials) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/downloader/klientix/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            ...connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}
