import cfg from '../../../config'
import { getInstance } from '../../auth'
import { Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(
    connector: Connector,
    credentials: { token: string; secret: string; url: string; companyId: string }
) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/downloader/dentalPro/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            ...connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status > 300) {
        return false
    } else {
        return true
    }
}
