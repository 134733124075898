<template>
    <div class="auth">
        <div class="mx-0 my-10">
            <h2 class="dashboard">Профсалон</h2>
        </div>
        <div class="mt-6">
            <v-form class="form" v-model="valid">
                <TextField label="Логин ПрофСалон" prependInnerIcon='mdi-account' v-model="credentials.pfLogin" required />
                <TextField
                    prependInnerIcon='mdi-lock'
                    label="Пароль ПрофСалон"
                    v-model="credentials.pfPassword"
                    required
                    type="password"
                />
            </v-form>

            <span>Выполните </span
            ><a
                href="https://cdn.discordapp.com/attachments/936566367073730600/1035284612391383110/Sozdanie_polzovatelya_ProfSalon_dlya_podklucheniya_k_Data_Heroes.pdf"
                >инструкцию</a
            >
        </div>
        <v-row class="ma-0" justify="end">
            <v-btn :disabled="btnDisabled" :loading="loading" color="main" class="white--text" @click="login"
                >Далее</v-btn
            >
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@/api/connectors/altegio'
import cfg from '../../../../config'
import { createPendingConnectorRecord, getWebhook } from '@root/src/api/pendingConnectors'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import { saveCredentials } from '@root/src/api/connectors/profsalonBat'

export default {
    name: 'ProfSalonBatAuth',
    components: { TextField, HintIcon },
    data: () => ({
        valid: false,
        loading: false,
        credentials: {
            pfLogin: '',
            pfPassword: '',
        },
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        btnDisabled() {
            return !this.credentials.pfLogin || !this.credentials.pfPassword
        },
    },
    methods: {
        async login() {
            this.loading = true
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: this.currentAddingConnector.type,
            })

            this.$store.commit('project/setLoggedAddingConnector', connector)

            const { data, error } = await saveCredentials(connector, this.credentials)
            if (!error) {
                await createPendingConnectorRecord({
                    userName: this.$auth.user.name,
                    userEmail: this.$auth.user.email,
                    projectId: connector.projectId,
                    projectName: this.project.name,
                    connectorId: connector.connectorId,
                    userId: this.$auth.user.sub,
                    connectorType: this.currentAddingConnector.type,
                    webhookKey: this.webhookData.webhookKey,
                    stage: 1,
                })
                this.$parent.$emit('login')
            } else {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.loading = false
        },
    },
    async created() {
        this.webhookData = await getWebhook()
    },
}
</script>
<style lang="sass" scoped>
.form
    max-width: 400px
</style>
