<template>
    <div class="auth">
        <div class="mx-0 my-10">
            <h2 class="dashboard">Подключение Data Heroes к вашему Altegio</h2>
        </div>
        <div class="mt-6">
            <span>Выполните </span
            ><a
                href="http://help.dataheroes.pro/?slug=987-podklyuchenie-altegio"
                >инструкцию</a
            >
        </div>
        <v-row class="ma-0" justify="end">
            <v-btn color="main" class="white--text" @click="loginMeth">Далее</v-btn>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@/api/connectors/altegio'
import cfg from '../../../../config'
import { createPendingConnectorRecord } from '@root/src/api/pendingConnectors'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default {
    name: 'AltegioAuth',
    components: { TextField, HintIcon },
    data: () => ({}),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        async loginMeth() {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.altegio,
            })
            //Дальше логин со стандартными кредсами
            const isLogin = await login({
                projectId,
                connector,
            })
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
                await createPendingConnectorRecord({
                    userName: this.$auth.user.name,
                    userEmail: this.$auth.user.email,
                    projectId: connector.projectId,
                    projectName: this.project.name,
                    connectorId: connector.connectorId,
                    userId: this.$auth.user.sub,
                    connectorType: CONNECTOR_TYPES.altegio,
                    webhookKey: null,
                    stage: 1,
                })
            }
        },
    },
}
</script>
<style lang="sass" scoped></style>
